import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import moment from "moment";
import DfCoupon from "@/vue/domain/df-coupon";
import Firebase from "@/vue/infrastructure/utils/firebase";
import DfFirebaseTopicType from "@/vue/domain/firebase/df-firebase-topic-type";
import DfBrand from "@/vue/domain/brand/df-brand";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfStore from "@/vue/domain/store/df-store";
import Swal from "sweetalert2";

@Component({
  components: {
    DfHeaderMobile: () => Utils.externalComponent2("df-header-mobile"),
    DfStoreCard: () => Utils.externalComponent2("df-store-card"),
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
  },
  filters: {
    percentual: (value: string) => {
      const data: string = new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        minimumFractionDigits: 0,
      }).format(parseFloat((parseFloat(value) || 0).toFixed(0)));
      return `-${data}%`;
    },
    decimal: (value: string) => {
      return new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        minimumFractionDigits: 2,
      }).format(parseFloat((parseFloat(value) || 0).toFixed(2)));
    },
    number: (value: number) => {
      return new Intl.NumberFormat(navigator.language).format(value);
    },
  },
})
export default class DfPageCouponDetailsComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;
  private busy = false;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get coupon(): DfCoupon {
    return this.couponList.find((coupon: DfCoupon) => coupon.code === this.$route.params.couponCode);
  }

  get couponList(): Array<DfCoupon> {
    return this.$store.getters.couponList;
  }

  set couponList(couponList: Array<DfCoupon> ) {
    this.$store.dispatch("setCouponList", couponList);
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get dateAvailableTo(): string {
    return this.coupon.availableTo ? moment(this.coupon.availableTo, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
  }

  get daysToExpire(): number {
    let daysToExpire = 0;
    if (this.coupon.availableTo) {
      daysToExpire = moment(this.coupon.availableTo, Utils.ISO_8601_STRING_PATTERN).diff(moment(), "days");
    }
    return daysToExpire;
  }

  get discount(): string {
    let discount: string = null;
    let data: string = null;
    if (this.coupon?.discount) {
      switch (this.coupon.discountType) {
        case DfCoupon.DISCOUNT_PERCENT:
          data = new Intl.NumberFormat(navigator.language, {
            style: "decimal",
            minimumFractionDigits: 0,
          }).format(parseFloat((parseFloat(this.coupon.discount.toString()) || 0).toFixed(0)));
          discount = `-${data}%`;
          break;
        case DfCoupon.DISCOUNT_CASH:
          data = new Intl.NumberFormat(navigator.language, {
            style: "currency",
            currency: "EUR",
          }).format(parseFloat((parseFloat(this.coupon.discount.toString()) || 0).toFixed(2)));
          discount = `-${data}`;
      }
    }
    return discount;
  }

  get discountTrigger(): string {
    if (!this.coupon) return null;

    if (this.coupon.minMoney) {
      const minMoney = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR",
      }).format(parseFloat((parseFloat(this.coupon.minMoney.toString()) || 0).toFixed(2)));
      return this.$t("df-card-coupon.min-money", { 0: minMoney }).toString();
    } else if (this.coupon.maxMoney) {
      const maxMoney = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR",
      }).format(parseFloat((parseFloat(this.coupon.maxMoney.toString()) || 0).toFixed(2)));
      return this.$t("df-card-coupon.max-money", { 0: maxMoney }).toString();
    } else if (this.coupon.minUnits) {
      return this.$tc("df-card-coupon.min-units", this.coupon.minUnits, { 0: this.coupon.minUnits }).toString();
    } else if (this.coupon.maxUnits) {
      return this.$tc("df-card-coupon.max-units", this.coupon.minUnits, { 0: this.coupon.maxUnits }).toString();
    } else {
      return this.$tc("df-card-coupon.discount");
    }
  }

  get imageUrl(): string {
    return this.coupon?.image || Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_COUPON_DETAIL_IMAGE, null);
  }

  get termsAndConditionsText() {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_COUPON_TERMS_AND_CONDITIONS, "TEXT");
  }

  beforeMount() {
    if (Utils.enableSaviCoupon()) {
      console.log("SMT: Savi coupon enabled");
    } else if (Utils.enableLoyalGuruCoupon() && !this.coupon) {
      this.getCoupons();
    } else if (!Utils.enableLoyalGuruCoupon()) {
      this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
    }
  }

  private getCoupons() {
    this.busy = true;
    this.service
      .getVoucherAll({
        status: DfCoupon.STATUS_AVAILABLE,
        storeAlias: this.currentStore.alias,
      })
      .then((coupons: Array<DfCoupon>) => {
        this.couponList = coupons;
        this.busy = false;
      })
      .catch((error: Error) => {
        Utils.defaultApiErrorHandler(error, this);
        this.busy = false;
      });
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private showTermsAndConditionsPolicy() {
    Swal.fire({
      title: this.$t("df-card-coupon.swal.terms.title").toString(),
      html: this.termsAndConditionsText,
      icon: "info",
      customClass: "privacy-modal",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showCancelButton: false,
      confirmButtonText: this.$t("df-card-coupon.swal.terms.confirm").toString(),
    });
  }

  private toggleCoupon() {
    const action: string = this.coupon.selected ? DfCoupon.ACTION_DESELECT : DfCoupon.ACTION_SELECT;
    this.updateCoupon(action, [this.coupon.code]);
  }

  private updateCoupon(action: string, codes: Array<string>) {
    if (!this.busy) {
      this.busy = true;
      this.service
        .selectVoucher({
          action: action,
          codes: codes,
        })
        .then(() => {
          this.getCoupons();
        })
        .catch((error: Error) => {
          Utils.defaultApiErrorHandler(error, this);
          this.busy = false;
        });
    }
  }
}
